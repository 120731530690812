.form-control {
  height: 2em;
  padding: .2em;
  font-size: .85em;
  @include border-radius($border-radius-sm, 0);
}
.wf-input-date {
  border-top: none;
  border-right: none;
  border-bottom: 1px dashed #08c;
  border-left: none;
}
