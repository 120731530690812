.wf-table {
  margin-top: .5rem;
  border-spacing: 0 2px;
  border-collapse: separate;
  td {
    border-bottom: none;
  }
  .wf-head-cell {
    color: $wf-dark-grey;
    vertical-align: baseline;
  }
  tr:nth-of-type(2n+0) {
    background-color: $wf-soft-grey;
  }
  tr:nth-of-type(2n+1) {
    background-color: $wf-min-grey;
  }
  .wf-head-cell:not(:first-of-type) {
    border-left: 1px solid $white;
  }
  .wf-body-cell:not(:first-of-type) {
    border-left: 1px solid $wf-dark-grey;
  }
  .wf-table-row {
    cursor: pointer;
  }
  .wf-table-row:hover {
    /* stylelint-disable */
    //color: $wf-dark-grey !important;
    background-color: $wf-light-grey !important;
    /* stylelint-enable */
  }
}
.wf-table-head {
  margin-bottom: .5rem;
  background-image: url("/img/verlauf_balken.gif");
  background-repeat: repeat-x;
  background-size: auto 100%;
  border: 1px solid $wf-dark-grey;
  th {
    border: none;
  }
  .wf-sortable {
    display: flex;
    flex-direction: column;
    a {
      line-height: 0;
    }
  }
}
