body {
  overflow-y: scroll;
}
main {
  margin-bottom: 4rem;
  .editable-container.editable-inline {
    position: relative;
    z-index: 1000;
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
    background-color: $white;
  }
  .editable-input {
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
    .form-control {
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .wf-container-nopadding {
    padding-right: 0;
    padding-left: 0;
  }
  .wf-trenner {
    display: block;
    flex: unset;
    margin-right: 15px;
    margin-left: 15px;
    border-top: 1px solid $wf-light-grey;
    @include media-breakpoint-down(sm) {
      /*
      padding-right: 0;
      padding-left: 0;
      margin-left: 1rem;
      */
    }
  }
  .wf-row {
    margin-bottom: 1rem;
    .wf-title {
      font-weight: 700;
      color: $wf-dark-grey;
    }
  }
  .wf-card-title {
    font-weight: 700;
    color: $wf-dark-grey;
  }
  nav {
    margin-bottom: 1rem;
  }
  .form-group {
    margin-bottom: .7rem;
  }
  .card-header.adress-ctrl {
    /*
    padding: .5rem 2.2rem .2rem;
    .checkbox {
      float: right;
      margin-left: 1rem;
    }
    input {
      margin-right: .5rem;
    }
    */
  }
  @include media-breakpoint-down(sm) {
    .card {
      display: block;
      flex-direction: unset;
    }
    .card-header.adress-ctrl {
      height: 4.8rem;
    }
  }
}
.viewstore {
  display: none;
}
.waiter {
  position: fixed;
  z-index: 1000;
  display: none;
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  background-color: $wf-light-grey;
  opacity: .5;
}
