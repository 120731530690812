.wf-wrapper {
  position: relative;
  min-height: 100vh;
}
header {
  .form-control {
    height: 1rem;
    padding: 1rem .75rem;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 1rem;
    .wf-logo-wppt {
      margin-left: -58px;
    }
    .nav {
      margin-top: 2rem;
    }
    .nav-tabs {
      border-bottom: none;
    }
    .nav-tabs .nav-item {
      margin-bottom: 0;
    }
    .nav-tabs .nav-link {
      border-bottom: none;
    }
    .nav-item:not(:last-child) {
      min-width: 8rem;
      margin-right: .3rem;
      .dropdown-toggle::after {
        position: absolute;
        top: .8rem;
        right: 1rem;
        left: auto;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wf-logo-wppt {
      margin-bottom: 1rem;
    }
    .wf-nav-favorits .form-inline {
      flex-flow: row nowrap;
    }
  }
}

.wf-nav-favorits {
  min-height: 3rem;
  margin-bottom: 1rem;
  background-color: $wf-dark-grey;
  form {
    margin-top: .5rem;
  }
}
