.pagination {
  margin: 0;
}
.wf-pagination {
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: .8rem;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem;
  line-height: 1rem;
  color: $wf-dark-grey;
  text-align: center;
  text-decoration: none;
  background-color: $wf-light-grey;
}
.wf-pagination:hover {
    color: $wf-light-grey;
    background-color: $wf-dark-grey;
    text-decoration: none;
}
.wf-pagination-last::before {
  content: "\f101";
}
.wf-pagination-first::before {
  content: "\f100";
}
.wf-pagination-next::before {
  content: "\f105";
}
.wf-pagination-prev::before {
  content: "\f104";
}
.wf-pagination-current {
  line-height: .7rem;
}
.wf-pagination-current::before {
  font-size: .3rem;
  content: "\f111";
}
