.btn-inline {
  width: 2rem;
  padding: 0;
  font-size: .9rem;
  line-height: 1rem;

  .fa-external-link-alt {
    padding: .1rem .1rem .3rem .3rem;
  }
  .fa-envelope-square {
    padding: .2rem;
  }
}
.wf-btn {
  color: $wf-dark-grey;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}
.wf-close-btn {
  color: $wf-dark-grey;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}
.wf-table td {
  position: relative;
}
.wf-copy-btn  {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  font-size: .7rem;
  line-height: .7rem;
  padding: 0;
  color:  $wf-dark-grey;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}
.wf-copy-btn::before {
  content: "\f0c5";
}
.wf-wfuser {
    .btn-sm {
        min-width: 10rem;
        padding: 0 .2rem;
    }
}
