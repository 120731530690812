footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 1rem;
  .wf-statusbar {
    min-height: 2.5rem;
    background-color: $wf-dark-grey;
    //@include border-top-radius($nav-tabs-border-radius);
    //@include border-bottom-radius($nav-tabs-border-radius);
  }
}
