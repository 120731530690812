@media (min-width: 768px) {
  .wf-col-1{
    flex: 0 0 24.5%;
    max-width: 24.5%;
  }
  .wf-col-11{
    flex: 0 0 75.5%;
    max-width: 75.5%;
  }
}
@media (min-width: 992px) {
  .wf-col-1{
    flex: 0 0 12%;
    max-width: 12%;
  }
  .wf-col-11{
    flex: 0 0 88%;
    max-width: 88%;
  }
}
.wf-docs-editablefield,
.wf-docs-editableposition {
  @include border-radius($border-radius-sm, 0);
  width: 100%;
  min-height: 2rem;
  padding: .4em;
  border: 1px solid $wf-light-grey;
}
.wf-docs-editableposition {
  font-size: .8em;
}
.wf-docs-input-text {
  @include border-radius($border-radius-sm, 0);
  width: 100%;
  border: 1px solid $wf-light-grey;
}
.wf-docs-position,
.wf-border-bottom-dotted {
  padding-bottom: 1rem;
  border-bottom: 1px dotted $wf-light-grey;
}
.wf-docs-position {
  margin-right: auto;
  margin-left: auto;

  div {
    padding-right: 1px;
    padding-left: 1px;
  }

  input {
    @include border-radius($border-radius-sm, 0);
    font-size: .8em;
    border: 1px solid $wf-light-grey;

    &:not([type="checkbox"]) {
      width: 100%;
    }

    &:read-only {
      margin: 0;
      appearance: textfield;
    }
  }

  .position-ctrl {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    height: 2rem;
    font-size: 1.2rem;
    text-align: right;

    i {
      cursor: pointer;

      &:hover {
        font-size: 1.5rem;
      }
    }

    .wf-position-moveup {
      color: $green;
    }

    .wf-position-movedown {
      color: $green;
    }

    .wf-position-clone {
      color: $blue;
    }

    .wf-position-delete {
      color: $red;
    }
  }
  .add-position {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 1.4rem;
    color: $green;
    cursor: pointer;

    &:hover {
      font-size: 1.5rem;
    }
  }
}
.positions .wf-row {
  &:last-child {
    .position-ctrl .wf-position-movedown {
      display: none;
    }
  }
  &:first-child {
    .position-ctrl .wf-position-moveup {
      display: none;
    }
  }
}

.document-ctrl {
  font-size: 1.2rem;
  color: $wf-dark-grey;
  text-align: right;

  i {
    cursor: pointer;

    &:hover {
      font-size: 1.5rem;
    }
  }
}
.wf-clickable-icons-behavior {
  position: absolute;
  right: 0;
  bottom: 50%;
  margin-bottom: -.5rem;
  font-size: 1.2rem;
  color: $wf-dark-grey;
  cursor: pointer;

  &:hover {
    font-size: 1.5rem;
  }
}
