.jobstatus-icon {
  width: 15px;
}
.jobstatus-icon:not(:last-child) {
  margin-right: 1px;
}
.wf-latest {
  font-weight: 400;
}
.wf-container-comments {
  .row:not(:last-child) {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $wf-light-grey;
  }
  .wf-comments-new {
    width: 100%;
    height: 8rem;
    border: 1px solid $wf-light-grey;
  }
}
