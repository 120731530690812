.wf-accordion {
  .card {
    margin-bottom: 1.5rem;
    .card-header {
      padding: .2rem 0;
      border: none;
      button {
        width: 100%;
        font-weight: 700;
        color: $wf-dark-grey;
        text-align: left;
        text-decoration: none;
      }
    }
  }

}
